<template>
  <div class="day-off-block" @click="removeModal">
    <div class="day-off-text">
      <div>Start: {{ item.startDate | dateFormat }}</div>
    </div>

    <div class="day-off-text">
      <div>End: {{ item.endDate | dateFormat }}</div>
    </div>

    <div class="day-off-text">
      <div>{{ item.reasonType | reasonFormat }}</div>
    </div>

    <div class="close-btn"> +</div>
  </div>
</template>

<script>

export default {
  filters: {
    dateFormat(val) {
      const date = new Date(val);
      const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
      return date.toLocaleDateString("en-US", options);
    },
    reasonFormat(val) {
      if (!val && val !== 0) return null
      const reasonType = [
        "PTO", "Requested Off"
      ]
      return reasonType[Number(val)]
    }
  },
  props: {
    item: null,
    id: null,
  },
  methods: {
    removeModal() {
      this.$emit("remove-day-off", this.id);
    },
  }
}
</script>

<style scoped>
.day-off-block {
  position: relative;
  border: 1px solid;
  margin-bottom: 0.5rem;
}

.day-off-text {
  display: flex;
  justify-content: center;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  background: ghostwhite;
  display: none;
  transform: rotate(45deg);
  cursor: pointer;
  font-size: 1.5rem;
  color: #6E6B7B;
  border-radius: 50%;
  border: 1px solid;
  height: 1.5rem;
  width: 1.5rem;
  align-items: center;
  justify-content: center;
}

.day-off-block:hover .close-btn {
  display: flex;
}
</style>